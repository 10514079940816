import Element from 'element-plus'
import 'element-plus/dist/index.css';
import '@/assets/style/common.scss';
import router from '@/router';


const install = (app) => {
  app.use(Element);
  app.use(router);
}
export default install;