import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  // { path: '/', redirect: '/largeScreen' },
  {
    path: '/', name: 'largeScreen', component: () => import('@/page/largeScreen/index.vue'),
  },
  {
    path: '/', name: 'page', component: () => import('@/page/index.vue'),
    children: [
      {
        path: '/home', name: 'home', component: () => import('@/page/home/index.vue'),
      },
      {
        path: '/activityManagement', name: 'activityManagement', component: () => import('@/page/activityManagement/index.vue'),
      },
      {
        path: '/lotteryManagement', name: 'lotteryManagement', component: () => import('@/page/lotteryManagement/index.vue'),
      }
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;